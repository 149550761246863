<template>
  <!-- 标签 -->
  <div class="selectBox-group-item">
          <span>标签</span>
        <el-select
        class="w190 m-r-8 m-l-8"
          v-model="label"
          multiple
          collapse-tags
          placeholder="请选择"
        >
          <el-option
            v-for="item in infoLabelList"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          >
          </el-option>
        </el-select>
        </div>
</template>

<script>
export default {
    props: {
        infoLabelList:{
            type:Array,
            default:() =>[]
        }
    },
    data() {
        return {
          label:[]
        }
    }
}
</script>

<style>

</style>