<template>
  <!-- 月发文数量 -->
  <div>
    <span>发文数量</span>
    <el-input
    class="w190 m-r-8 m-l-8"
      v-model.trim="contentCount.cnt"
      clearable
      placeholder="请输入"
      :maxlength="$main.inputMaxLength()"
      @input="contentCount.cnt = contentCount.cnt.replace(/[^\d]/g, '')"
    ></el-input>
    <el-select
      class="w80"
      v-model="contentCount.above"
      placeholder="请选择"
    >
      <el-option
        v-for="item in options"
        :key="item.value"
        :label="item.label" 
        :value="item.value"
      >
      </el-option>
    </el-select>
  </div>
</template>

<script>
export default {
  data() {
    return {
      contentCount: {
        cnt: null,
        above: true,
      },
      options: [
          { label: "以上", value: true },
          { label: "以下", value: false },
        ],
    };
  },
};
</script>

<style>
</style>