<template>
  <div >
    <span>粉丝数量</span>
    <!-- 粉丝输入框 -->
    <el-input
      class="w190 m-r-8 m-l-8"
      v-model.trim="fans.cnt"
      clearable
      :maxlength="$main.inputMaxLength()"
      placeholder="请输入"
      @input="fans.cnt = fans.cnt.replace(/[^\d]/g, '')"
    ></el-input>
    <!-- 以上 || 以下 -->
    <el-select
      class="w80"
      v-model="fans.above"
      placeholder="请选择"
    >
      <el-option
        v-for="item in options"
        :key="item.value"
        :label="item.label"
        :value="item.value"
      >
      </el-option>
    </el-select>
  </div>
</template>

<script>
export default {
  data() {
    return {
      fans: {
        above: true,
        cnt: null,
      }, //粉丝数量
      options: [
          { label: "以上", value: true },
          { label: "以下", value: false },
        ],
    };
  },
  methods:{
    getData:() =>  this.fans
  }
};
</script>