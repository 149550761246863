<template>
  <div>
    <span class="m-r-8">{{title}}</span>
   <el-select
          class="select-address"
          v-model="status"
          multiple
          collapse-tags
          placeholder="请选择"
          size="medium"
        >
          <el-option
            v-for="item in statusList"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          >
          </el-option>
        </el-select>
  </div>

</template>

<script>
export default {
  props:{
    title:{
      type:String,
      default:''
    },
    isHaveNormal:{
      type:Boolean,
      default:false
    }
  },
    data() {
        return {
            status: null, //已选平台状态
        statusList:
          JSON.parse(window.localStorage.getItem("message_type")) || [], //平台状态
        }
    },
    mounted(){
      if(this.isHaveNormal) this.statusList = [{label:'正常',value:'0'}].concat(JSON.parse(window.localStorage.getItem("message_type")))
    },
    methods:{
        // 父组件获取数据
        getData:() => this.status
    }
}
</script>

<style scoped lang="scss">
// /deep/ .el-tag, .el-tag--info, .el-tag--small, .el-tag--light {
//   width: 50%;
// }
::v-deep .el-select__tags{
  span {
    display: flex;
    width: 80%;
    span:nth-child(2){
    .el-select__tags-text{
      width: 30px;
    }
  } 
}
  
}
 .select-address {
        width: 190px;
        height: 36px;
        ::v-deep .el-input__inner {
          width: 190px;
          height: 36px;
          border-radius: 4px;
        }
        ::v-deep .el-select__caret.el-input__icon.el-icon-arrow-up {
          display: flex;
          align-items: center;
          justify-content: center;
        }
        ::v-deep .el-input__icon.el-icon-arrow-down {
          display: flex;
          align-items: center;
          justify-content: center;
        }
        ::v-deep .el-input__icon.el-icon-circle-close {
          display: flex;
          align-items: center;
          justify-content: center;
        }
        ::v-deep .el-cascader {
          height: 36px !important;
          margin-top: 0px;
          .el-input.el-input--suffix {
            height: 36px;
            display: flex;
            align-items: center;
          }
        }
      }

</style>